import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton, BTooltip } from "bootstrap-vue";
import VueSelect from "vue-select";
import { ITemplateDocument, ICompany, ISale } from "@lib";
import { Printer } from "@core/usecases/template/printer";
import { SalePrintContext } from "@core/usecases/template/macro-replacers/sale-document.macro-replacer";

@Component({
    name: "sale-view-stage",
    components: {
        BButton,
        BTooltip,
        VueSelect,
    },
})
export default class SaleViewPrint extends Vue {
    @Prop({ required: true })
    private sale!: ISale;

    @Prop({ type: Array, required: true })
    private documents!: ITemplateDocument[];

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    private documentsSelected: ITemplateDocument[] = [];

    private company!: ICompany;

    @Watch("sale", { immediate: true, deep: true })
    private onSaleChanged(): void {
        this.initValues();
    }

    public async mounted(): Promise<void> {
        try {
            this.company = await this.$info.getCompany();
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        } finally {
            this.$alt.loader.hide();
        }
    }

    private initValues(): void {
        this.documentsSelected = [];
    }

    private print(): void {
        if (!this.sale.shopRef) {
            return;
        }

        const context: SalePrintContext = {
            company: this.company,
            shop: this.sale.shopRef,
            sale: this.sale,
        };

        Printer.printSaleDocuments(this.documentsSelected, context);
    }
}
