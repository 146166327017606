import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BFormInput, BListGroup, BListGroupItem, BBadge } from "bootstrap-vue";
import { IGood, IStore, IReadQuery } from "@lib";
import { ISaleGoodItem } from "./sale-create-goods";
import { DelayedOperation } from "@/utils/delayed-operation";

@Component({
    name: "sale-search-goods-old",
    components: {
        BFormInput,
        BListGroup,
        BListGroupItem,
        BBadge,
    },
})
export default class SaleSearchGoodsOld extends Vue {
    @Prop({ type: Number, required: true })
    private id!: number;

    @Prop({ type: Array, default: () => [] })
    private availableStores!: IStore[];

    private search: string = "";
    private searchGoodModal = {
        goods: [] as IGood[],
        total: 0,
    };

    @Watch("id")
    private onIdChanged(): void {
        this.initValues();
    }

    public mounted(): void {
        this.initValues();
    }

    private initValues(): void {
        this.search = "";
        (this.$refs.searchInput as any).focus();
    }

    private async changeSearch(): Promise<void> {
        DelayedOperation.invoke("search-good", 700, () => this.searchGoods(this.search));
    }

    private async searchGoods(search: string): Promise<void> {
        try {
            if (this.search.length < 2 || this.availableStores.length === 0) {
                return;
            }

            const company = await this.$info.getCompany();
            const options: IReadQuery = {
                search,
                filter: {
                    quantity: { gt: 0 },
                    store: { in: this.availableStores.map(store => store.id) },
                },
            };
            const result = await this.$alt.system.usecase.createGoodUseCase().select(company.id, options);

            const goods = result.data;
            if (goods.length === 1) {
                await this.chooseGood(goods[0]);
            }

            if (goods.length > 1) {
                this.searchGoodModal.goods = goods.slice(0, 10);
                this.searchGoodModal.total = goods.length;
                this.$bvModal.show("modal-search-good");
            }

            if (goods.length === 0) {
                this.$alt.toast.warning("Ничего не найдено.");
            }
        } catch (e: any) {
            this.$alt.toast.error(e.message);
        }
    }

    private async chooseGood(orig: IGood): Promise<void> {
        const dto: ISaleGoodItem = {
            sku: orig.info.sku,
            name: orig.info.name,
            description: orig.info.description,
            cost: orig.info.cost,
            price: orig.info.price,
            quantity: 1,
            warranty: orig.info.warranty,

            storeId: orig.store,
            goodId: orig.id,
            goodRef: orig,
        };

        this.$emit("found", dto, orig);
    }

    private async chooseGoodAndHideModal(good: IGood): Promise<void> {
        await this.chooseGood(good);
        this.$bvModal.hide("modal-search-good");
    }
}
