import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton, BSidebar, VBTooltip } from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { IShop, ISale, IAccount, IStore, IEmployee, IUser, ICompany, ISaleStage } from "@lib";
import { ITemplateDocument, IDiscount, DiscountType } from "@lib";
import { VisibilityChangedEventArgs } from "@core/components/alt-ui";
import { ModalComponent } from "@core/components/alt-ui/modal";
import SaleViewTitle from "./sale-view-title.vue";
import SaleViewStage from "./sale-view-stage.vue";
import SaleViewSeller from "./sale-view-seller.vue";
import SaleViewPrint from "./sale-view-print.vue";
import SaleViewInfo from "./sale-view-info.vue";
import SaleViewTabs from "./sale-view-tabs/sale-view-tabs.vue";
import { SaleViewForm } from "./sale-view-form";
import { ISaleGoodItem } from "./sale-view-tabs/sale-view-tabs-goods";

@Component({
    components: {
        BButton,
        BSidebar,
        VuePerfectScrollbar,
        ModalComponent,
        SaleViewTitle,
        SaleViewStage,
        SaleViewSeller,
        SaleViewPrint,
        SaleViewInfo,
        SaleViewTabs,
    },
    directives: { "b-tooltip": VBTooltip },
})
export default class SaleViewFormComponent extends Vue {
    @Prop({ type: Object, required: true })
    private handler!: SaleViewForm;

    @Prop({ type: Array, default: () => [] })
    private shops!: IShop[];

    @Prop({ type: Array, default: () => [] })
    private stores!: IStore[];

    @Prop({ type: Array, default: () => [] })
    private accounts!: IAccount[];

    @Prop({ type: Array, default: () => [] })
    private employees!: IEmployee[];

    @Prop({ type: Array, default: () => [] })
    private stages!: ISaleStage[];

    @Prop({ type: Array, default: () => [] })
    private documents!: ITemplateDocument[];

    @Prop({ type: Boolean, default: false })
    private canUpdate!: boolean;

    @Prop({ type: Boolean, default: false })
    private canDelete!: boolean;

    public visible: boolean = false;

    private discount: IDiscount | null = null;
    private goodItems: ISaleGoodItem[] = [];
    private total: number = 0.0;

    private updateTabsKey = 1;

    private company!: ICompany;
    private user!: IUser;
    private SaleUseCase = this.$alt.system.usecase.createSaleUseCase();

    private get sale(): ISale | null {
        return this.handler.sale;
    }

    private get readonly(): boolean {
        return this.sale?.done || !this.canUpdate;
    }

    public async mounted(): Promise<void> {
        this.init();
        await this.initValues();
    }

    @Watch("handler")
    private onHandlerChanged(): void {
        this.init();
    }

    @Watch("handler.sale")
    private onSaleChanged(value: ISale): void {
        if (value) {
            this.initValues();
        }
    }

    private init(): void {
        this.handler.addVisibleChangedHandler(this.onSidebarVisibleChanged.bind(this));
        //this.handler.addValidateHandler(this.onSidebarValidate.bind(this));
    }

    public close(): void {
        this.handler.hide();
    }

    private onSidebarVisibleChanged(sender: any, e: VisibilityChangedEventArgs): void {
        this.$nextTick(() => {
            this.visible = e.visible;
        });
    }

    private async initValues(): Promise<void> {
        this.discount = null;
        this.goodItems = [];
        this.total = 0.0;

        this.company = await this.$info.getCompany();
        this.user = await this.$info.getUser();

        this.discount = this.sale?.info?.discount ?? null;

        this.updateTabs();
        this.updateTotal();
    }

    private async startComplete(): Promise<void> {
        if (!this.sale || !this.handler.onComplete) {
            return;
        }

        await this.handler.onComplete(this.sale);
    }

    private async startReopen(): Promise<void> {
        if (!this.sale || !this.handler.onReopen) {
            return;
        }

        await this.handler.onReopen(this.sale);
    }

    private async confirmDelete(): Promise<void> {
        if (!this.sale || !this.handler.onDelete) {
            return;
        }

        await this.handler.onDelete(this.sale);
    }

    private changeSale(sale: ISale): void {
        this.$emit("sale-changed", sale, this.sale);
    }

    private updateTotal(): number {
        let actual = 0.0;
        for (const good of this.goodItems) {
            actual += good.price * good.quantity;
        }

        let discountTotal = 0.0;
        if (this.discount?.type === DiscountType.Percent) {
            discountTotal = (actual * this.discount.value) / 100;
        } else if (this.discount?.type === DiscountType.Fixed) {
            discountTotal = this.discount.value;
        }

        this.total = actual - discountTotal;

        return this.total;
    }

    private updateTabs(): void {
        this.updateTabsKey += 1;
    }
}
