import { ITemplateDocument, ISale } from "@lib";
import { Sidebar } from "@core/components/alt-ui/sidebar";
import { Control } from "@core/components/alt-ui/controls";
import { SaleCompleteModal } from "../sale-complete.modal";

type PrintEventHandler = (documents: ITemplateDocument[], sale: ISale) => void;

export interface ISaleCreateFormContext {
    completeModal?: SaleCompleteModal;
    onPrintDocuments?: PrintEventHandler;
}

export class SaleCreateForm extends Sidebar<ISaleCreateFormContext> {
    public completeModal: SaleCompleteModal | null = null;
    public onPrintDocuments: PrintEventHandler | null = null;

    public onOpening: (() => void) | null = null;
    public onClosed: (() => void) | null = null;

    private context: ISaleCreateFormContext | null = null;

    public constructor() {
        super("sale-create-form");
    }

    public show(context: ISaleCreateFormContext): Promise<void> {
        this.context = context;

        if (this.onOpening) {
            this.onOpening();
        }

        this.completeModal = this.context.completeModal ?? null;
        this.onPrintDocuments = this.context.onPrintDocuments ?? null;

        return super.show();
    }

    public hide(): void {
        super.hide();

        if (this.onClosed) {
            this.onClosed();
        }
    }

    public get controls(): Control[] {
        return [];
    }
}
