import { ISale, Currency, Locale, SaleStageType, ISaleStage } from "@lib";
import { Printer } from "@/@core/usecases/template/printer";
import { Localizer } from "@/i18n/localizer";
import * as filters from "@/filters";
import { IAltTableCellParams } from "@/core/components/alt-table";
import { SalePrintContext } from "@core/usecases/template/macro-replacers/sale-document.macro-replacer";

export function getDefaultTableColumns(context: any, showChangeStageModalFunc: Function): any[] {
    const stages = context.stages as ISaleStage[];

    return [
        {
            colId: "actions",
            headerName: "Изменить/удалить",
            width: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            minWidth: 70 + (context.can.update ? 25 : 0) + (context.can.delete ? 25 : 0),
            hide: false,
            headerClass: "text-transparent",
            headerCheckboxSelection: true,
            checkboxSelection: true,

            cellRendererFramework: "CellRendererActions",
            cellRendererParams: (params: IAltTableCellParams<ISale>): any => {
                return {
                    actions: [
                        {
                            id: "edit",
                            description: "Изменить",
                            icon: "Edit3Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-primary cursor-pointer",
                            isVisible: (sale: ISale) => context.can.update(sale),
                            click: (sale: ISale) => context.openFormUpdate(sale),
                        },
                        {
                            id: "delete",
                            description: "Удалить",
                            icon: "Trash2Icon",
                            classes: "h-1 w-1 mr-0.75 hover:text-danger cursor-pointer",
                            isVisible: (sale: ISale) => context.can.delete(sale),
                            click: (sale: ISale) => context.confirmDelete(sale),
                        },
                    ],
                };
            },
        },
        // {
        //     colId: "checkboxes",
        //     headerName: "Флажок",
        //     width: 50,
        //     hide: false,
        //     filter: false,
        //     checkboxSelection: true,
        //     headerCheckboxSelectionFilteredOnly: true,
        //     headerCheckboxSelection: true
        // },
        {
            colId: "number",
            field: "number",
            headerName: "#",
            sortable: true,
            filter: true,
            width: 70,
            hide: false,

            cellRenderer: (params: IAltTableCellParams<ISale>): string => {
                const value = params.value as string;
                const sale = params.data as ISale;

                if (!value || !sale.shopRef) {
                    return "";
                }

                const printContext: SalePrintContext = {
                    company: context.company,
                    shop: sale.shopRef,
                    sale: sale,
                };

                return Printer.replaceSaleDocumentsGeneralMacros("%(Продажа.Номер)", printContext);
            },
        },
        {
            colId: "shop",
            field: "shop",
            headerName: "Магазин",
            sortable: false,
            filter: true,
            width: 160,
            hide: true,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: IAltTableCellParams<ISale>): any => {
                return {
                    name: params.data.shopRef?.info.name,
                };
            },
            cellExport: (params: IAltTableCellParams<ISale>): string => {
                return params.data.shopRef?.info.name ?? "";
            },
        },
        {
            colId: "created",
            field: "createdAt",
            headerName: "Дата продажи",
            sortable: true,
            filter: true,
            width: 160,
            hide: false,

            cellRenderer: (params: IAltTableCellParams<ISale>): string => {
                return filters.datetime.datetime(params.value, "L LT");
            },
        },
        {
            colId: "goods",
            field: "goods",
            headerName: "Наименования",
            sortable: false,
            filter: true,
            width: 180,
            hide: false,
            autoHeight: true,

            cellRenderer: (params: IAltTableCellParams<ISale>): string => {
                const goods = params.value;

                let str = "";
                for (const good of goods) {
                    str += `<p class="text-sm m-0">${good.name} (${good.quantity} шт.)</p>`;
                }
                return `<div class="py-0.5">${str}</div>`;
            },
            cellExport: (params: IAltTableCellParams<ISale>): string => {
                const goods = params.value;

                let str = "";

                for (const good of goods) {
                    if (str.length > 0) {
                        str += " | ";
                    }

                    str += `${good.name} (${good.quantity} шт.)`;
                }

                return str;
            },
        },
        {
            colId: "comment",
            field: "info.comment",
            headerName: "Комментарий",
            sortable: false,
            filter: true,
            width: 300,
            hide: true,
            autoHeight: true,

            cellRenderer: (params: IAltTableCellParams<ISale>): string => {
                if (params.value && params.value.length > 0) {
                    return `<div class="text-sm whitespace-pre-wrap m-0 py-0.5" style="line-height: 1.5rem;">${params.value}</div>`;
                } else {
                    return "";
                }
            },
            cellExport: (params: IAltTableCellParams<ISale>): string => {
                return params.value ?? "";
            },
        },
        {
            colId: "paid",
            field: "paid",
            headerName: "Оплачено",
            sortable: false,
            filter: true,
            width: 180,
            hide: false,
            autoHeight: true,

            cellRenderer: (params: IAltTableCellParams<ISale>): string => {
                const sale = params.data as ISale;

                if (!sale.payments) {
                    return "";
                }

                let sum = 0;
                for (const payment of sale.payments) {
                    sum += payment.value;
                }

                const currency = sale.shopRef?.info.currency ?? Currency.RUB;
                const locale = sale.shopRef?.info.locale ?? Locale.RU;
                return Localizer.currency(sum, currency, locale);
            },
        },
        {
            colId: "stage",
            field: "stage",
            headerName: "Статус",
            sortable: false,
            filter: true,
            width: 160,
            hide: false,

            cellRendererFramework: "CellRendererChip",
            cellRendererParams: (params: IAltTableCellParams<ISale>): any => {
                const stage = stages.find(stage => stage.id === params.value);
                return {
                    color: stage ? stage.color : "dark",
                    name: stage ? stage.name : "-",
                    click: () => showChangeStageModalFunc(params.data),
                };
            },
            cellExport: (params: IAltTableCellParams<ISale>): string => {
                const stage = stages.find(stage => stage.id === params.value);
                return stage?.name ?? "-";
            },
        },
        // {
        //     colId: "client",
        //     field: "client",
        //     headerName: "Клиент",
        //     sortable: true,
        //     filter: true,
        //     width: 180,
        //     hide: false,
        //     cellClass: "alt-grid-cell-multiline",
        //     autoHeight: true,

        //     cellRendererFramework: "CellRendererAvatar",
        //     cellRendererParams: (params: IAltTableCellParams<ISale>): any => {
        //         const client = params.value;
        //         let name = "";
        //         let description = "";
        //         if (client) {
        //             if (client.info.name) {
        //                 name = client.info.name;
        //             }
        //             // if (client.info.description) {
        //             //     description += "\n" + client.info.description;
        //             // }
        //             // if (client.info.type) {
        //             //     description += "\n" + (client.info.type === "company" ? "Юридическое лицо" : "Физическое лицо");
        //             // }
        //             // if (client.info.taxId) {
        //             //     description += "\nИНН: " + client.info.taxId;
        //             // }
        //             if (client.info.contacts && client.info.contacts.email) {
        //                 description += "\n" + client.info.contacts.email;
        //             }
        //             if (client.info.contacts && client.info.contacts.phone) {
        //                 name += "\n" + client.info.contacts.phone;
        //                 description += "\n" + client.info.contacts.phone;
        //             }
        //         }

        //         return {
        //             tooltip: client ? client.info.name : "Клиент",
        //             name: name,
        //             click: () => { $router.push({ name: "clients", params: { id: client.id } }).catch(() => {}); }
        //         };
        //     },
        //     cellExport: (params: IAltTableCellParams<ISale>): string => {
        //         //
        //     },
        // },
        {
            colId: "seller",
            field: "seller",
            headerName: "Продавец",
            sortable: false,
            filter: true,
            width: 220,
            hide: false,

            cellRendererFramework: "CellRendererAvatar",
            cellRendererParams: (params: IAltTableCellParams<ISale>): any => {
                const seller = params.data.sellerRef;
                return {
                    showAvatar: true,
                    avatar: "", //seller?.info.avatar ?? "",
                    name: seller?.info.name ?? "",
                };
            },
            cellExport: (params: IAltTableCellParams<ISale>): string => {
                return params.data.sellerRef?.info.name ?? "";
            },
        },
    ];
}

//

export function getDefaultTableActions(context: any): any[] {
    return [
        {
            name: "Удалить всё",
            icon: "Trash2Icon",
            click: (sales: ISale[]) => context.confirmDeleteMany(sales),
        },
    ];
}

export function getSaleStageColor(stage: SaleStageType): string {
    switch (stage) {
        case SaleStageType.New:
        case SaleStageType.Open:
            return "primary";
        case SaleStageType.Processing:
        case SaleStageType.Delivery:
            return "warning";
        case SaleStageType.Ready:
            return "success";
        case SaleStageType.Paid:
        case SaleStageType.Closed:
        case SaleStageType.Canceled:
        case SaleStageType.Return:
            return "secondary";
    }
}

export function getSaleStageText(stage: SaleStageType): string {
    switch (stage) {
        case SaleStageType.New:
        case SaleStageType.Open:
            return "Создано";
        case SaleStageType.Processing:
            return "В сборке";
        case SaleStageType.Delivery:
            return "В доставке";
        case SaleStageType.Ready:
            return "Готово к выдаче";
        case SaleStageType.Paid:
        case SaleStageType.Closed:
            return "Закрыто";
        case SaleStageType.Canceled:
            return "Отменено";
        case SaleStageType.Return:
            return "Возвращено";
        default:
            return "Неизвестно";
    }
}
