import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import {
    ISale,
    ISaleGood,
    IStore,
    IDiscount,
    DiscountType,
    IGood,
    IAccount,
    IShop,
    Currency,
    IWarranty,
    Locale,
    ICompany,
    PermissionType,
    PermissionRight,
    IReadQuery,
} from "@lib";
import TableListComponent from "@core/components/alt-ui/table-list/table-list.component.vue";
import { TableAction, TableColumn, TableFooter } from "@core/components/alt-ui/table/table";
import { TableList } from "@core/components/alt-ui/table-list";
import { Modal2Component } from "@core/components/alt-ui/modal-2/modal-2.component";
import { NumberBox } from "@core/components/alt-ui/controls";
import { WarrantyUtils } from "@/utils/types/warranty.utils";
import SaleSearchGoods from "./sale-search-goods.vue";
import SaleSearchGoodsOld from "./sale-search-goods-old.vue";
import { SaleGoodModal } from "./sale-good.modal";
import { currencyHeader, moneyFormat } from "@/filters/money";
import { Uuid } from "@/utils/uuid";

export interface ISaleGoodItem {
    sku?: string;
    name: string;
    description?: string;
    cost: number;
    price: number;
    quantity: number;
    warranty?: IWarranty;

    accountId?: string;
    storeId?: string;
    goodId?: string;
    goodRef?: IGood;
}

@Component({
    name: "sale-create-good",
    components: {
        BButton,
        TableListComponent,
        Modal2Component,
        SaleSearchGoods,
        SaleSearchGoodsOld,
    },
})
export default class SaleCreateGoods extends Vue {
    @Prop({ type: Number, required: true })
    private tableId!: number;

    @Prop({ required: true })
    private sale!: ISale | null;

    @Prop({ default: () => null })
    private shop!: IShop | null;

    @Prop({ type: Array, required: true })
    private goods!: ISaleGood[];

    @Prop({ type: Array, default: () => [] })
    private stores!: IStore[];

    @Prop({ default: () => null })
    private discount!: IDiscount | null;

    private company!: ICompany;
    private accounts: IAccount[] = [];
    private items: ISaleGoodItem[] = [];
    private searchUpdateId = 1;
    private searchGoodsNew = true;

    private table: TableList<ISaleGoodItem>;
    private saleGoodModal: SaleGoodModal;

    private get isLargeScreen(): boolean {
        return this.$info.ui.windowWidth >= 768;
    }

    private get can(): any {
        const secure = this.$secure;

        return {
            readGoods(storeId: string): boolean {
                return secure.check(PermissionType.Stores, storeId, PermissionRight.GoodsRead);
            },
            saleGoods(storeId: string): boolean {
                return secure.check(PermissionType.Stores, storeId, PermissionRight.GoodsSale);
            },
        };
    }

    private get availableStores(): IStore[] {
        return this.stores.filter(s => this.can.readGoods(s.id) && this.can.saleGoods(s.id));
    }

    public constructor() {
        super();

        this.table = new TableList<ISaleGoodItem>();
        this.table.id = "sale-goods-table";
        this.table.mode = this.isLargeScreen ? "table" : "list";
        this.table.header = i => i.name.toUpperCase();
        this.table.columns = this.getTableColumns();
        this.table.actions = this.getTableActions();
        this.table.footer = this.getTableFooter();
        this.table.noDataText = "Пока нет товаров";
        //this.table.left = (item, index) => (index + 1).toString();
        this.table.onlyTableColumn = (column, item, index) => column.title === "#" || column.title === "Наименование";

        this.saleGoodModal = new SaleGoodModal();
        this.saleGoodModal.onGetGoodFromStore = this.getGoodFromStore.bind(this);
        this.saleGoodModal.onCreateNewGood = this.createNewGood.bind(this);
    }

    private getTableColumns(): TableColumn<ISaleGoodItem>[] {
        const columns: TableColumn<ISaleGoodItem>[] = [
            {
                title: "#",
                classHeader: "text-left",
                classCell: "text-left",
                cell: (item, index) => (index + 1).toString(),
            },
            {
                title: "Наименование",
                classHeader: "text-left",
                classCell: "text-left",
                cell: item => item.name,
            },
            {
                title: currencyHeader("Цена", this.currency),
                cell: item => moneyFormat(item.price, { locale: this.locale }),
            },
            {
                title: "Количество",
                cell: item => {
                    const number = new NumberBox();
                    number.id = Uuid.new();
                    number.max = item.goodRef?.info.quantity ?? Number.MAX_VALUE;
                    number.value = item.quantity;
                    number.class = "ml-auto mr-auto";
                    number.style = "max-width: 12rem;";
                    number.addValueChangedHandler((s, e) => {
                        item.quantity = e.value ?? 0;
                    });

                    return number;
                },
            },
            {
                title: currencyHeader("Стоимость", this.currency),
                cell: item => moneyFormat(item.price * item.quantity, { locale: this.locale }),
            },
        ];

        return columns;
    }

    private getTableActions(): TableAction<ISaleGoodItem>[] {
        const actions: TableAction<ISaleGoodItem>[] = [
            {
                title: "Удалить",
                icon: "Trash2Icon",
                class: "hover:text-danger",
                handler: async (item, index) => {
                    this.confirmDeleteItem(item);
                },
            },
        ];

        return actions;
    }

    private getTableFooter(): TableFooter<ISaleGoodItem>[] {
        const footer: TableFooter<ISaleGoodItem>[] = [];

        if (this.items && this.items.length > 0) {
            footer.push({
                title: "Итого:",
                cell: i => moneyFormat(this.totalPrice, { locale: this.locale }),
            });

            if (this.totalDiscount > 0) {
                footer.push({
                    title: this.discountText.length > 0 ? `Скидка ${this.discountText}:` : "Скидка:",
                    cell: i => moneyFormat(this.totalDiscount, { locale: this.locale }),
                });

                footer.push({
                    title: "К оплате:",
                    cell: i => moneyFormat(this.totalPrice - this.totalDiscount, { locale: this.locale }),
                });
            }
        }

        return footer;
    }

    private get currency(): Currency | undefined {
        return this.shop?.info?.currency;
    }

    private get locale(): Locale | undefined {
        return this.shop?.info?.locale;
    }

    private get totalPrice(): number {
        let total = 0.0;
        for (const item of this.items) {
            total += item.price * item.quantity;
        }
        return total;
    }

    private get discountText(): string {
        if (!this.discount) {
            return "";
        }

        switch (this.discount.type) {
            case DiscountType.Fixed:
                return ""; //moneyFormat(this.order.info.discount.value, { locale: this.locale, currency: this.currency });
            case DiscountType.Percent:
                return this.discount.value + "%";
        }

        return "";
    }

    private get totalDiscount(): number {
        if (this.discount?.type === DiscountType.Fixed) {
            return this.discount.value;
        } else if (this.discount?.type === DiscountType.Percent) {
            return (this.totalPrice * this.discount.value) / 100;
        }

        return 0;
    }

    @Watch("goods")
    private onGoodsChanged(): void {
        this.initValues();
    }

    @Watch("discount")
    private onDiscountChanged(): void {
        this.table.footer = this.getTableFooter();
    }

    private async mounted(): Promise<void> {
        this.accounts = await this.$info.getAccounts();
        await this.initValues();
    }

    private async initValues(): Promise<void> {
        this.company = await this.$info.getCompany();
        this.searchGoodsNew = localStorage.getItem("feature-search-goods-old") !== "1";

        this.items = this.goods.map(
            sg =>
                ({
                    sku: sg.sku,
                    name: sg.name,
                    description: sg.description,
                    cost: sg.cost,
                    price: sg.price,
                    quantity: sg.quantity,
                    warranty: sg.warranty,
                    goodId: sg.id,
                    //ref?: IGood;
                } as ISaleGoodItem),
        );

        this.table.items = this.items;
        this.table.columns = this.getTableColumns();
        this.table.actions = this.getTableActions();
        this.table.footer = this.getTableFooter();
        this.searchUpdateId += 1;
    }

    private goodsChanged(): void {
        this.table.items = this.items;
        this.table.footer = this.getTableFooter();

        this.$emit("change-goods", this.items);
    }

    //

    private async showSaleGoodModal(): Promise<void> {
        const modalSettings = await this.$settings.getSaleGoodModal();

        const context = {
            accounts: this.accounts,
            stores: this.availableStores,
            settings: modalSettings,
            searchGoods: this.searchGoods.bind(this),
        };

        await this.saleGoodModal.show(context);
    }

    private async createNewGood(dto: ISaleGoodItem): Promise<boolean> {
        this.items.push(dto);
        this.goodsChanged();
        this.$settings.setSaleGoodModal({
            accountWidthdrawal: !!dto.accountId,
            accountId: dto.accountId,
            storeAddition: !!dto.storeId,
            storeId: dto.storeId,
        });
        return true;
    }

    private async getGoodFromStore(dto: ISaleGoodItem, orig: IGood): Promise<boolean> {
        const existed = this.items.find(i => (i.goodId ? i.goodId === dto.goodId : false));

        if (existed) {
            // TODO: какое-то количество может быть уже взято ранее

            if (existed.quantity + dto.quantity > orig.info.quantity) {
                this.$alt.toast.warning(`Нельзя добавить больше ${orig.info.quantity} шт. товара "${orig.info.name}".`);
                return false;
            }

            existed.quantity += dto.quantity;
            existed.price = dto.price;
        } else {
            this.items.push(dto);
        }

        this.goodsChanged();
        return true;
    }

    private async foundGood(dto: ISaleGoodItem, orig: IGood): Promise<void> {
        if (await this.getGoodFromStore(dto, orig)) {
            this.searchUpdateId += 1;
            this.$alt.toast.success(`Товар "${orig.info.name}" добавлен.`, undefined, { autoHideDelay: 2000 });
        }
    }

    private async searchGoods(search: string): Promise<IGood[]> {
        try {
            if (this.availableStores.length === 0) {
                return [];
            }

            const companyId = this.company.id;

            const query: IReadQuery = {
                search,
                limit: 10,
                offset: 0,
                filter: {
                    quantity: { gt: 0 },
                    store: { in: this.availableStores.map(store => store.id) },
                },
            };
            const result = await this.$alt.system.usecase.createGoodUseCase().select(companyId, query);

            return result.data;
        } catch {
            return [];
        }
    }

    private async confirmDeleteItem(item: ISaleGoodItem): Promise<void> {
        const result = await this.$alt.message.confirm(
            `Удалить товар "${item.name}" (${item.quantity} шт.)?`,
            "Удаление товара",
            { acceptText: "Удалить" },
        );

        if (result) {
            this.deleteGood(item);
        }
    }

    private deleteGood(item: ISaleGoodItem): void {
        const index = this.items.findIndex(g => g === item);
        this.items.splice(index, 1);
        this.goodsChanged();
    }
}
