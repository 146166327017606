import { IDiscount, DiscountType, Locale, Currency } from "@lib";
import { ComboBox, Label, Panel, TextBox } from "../components/alt-ui/controls";
import { EventHandler, ValueChangedEventArgs } from "../components/alt-ui";
import { DiscountUtils } from "../../utils/types/discount.utils";
import { Formatter } from "@/utils/formatter";

export class DiscountBox extends Panel {
    public getComponentName(): string {
        return "PanelComponent";
    }

    private _label = "";
    public get label(): string {
        return this._label;
    }
    public set label(label: string) {
        this._label = label;
        this.lbLabel.text = label;
    }

    public get value(): IDiscount {
        const valueStr = this.tbValue.text.trim().replace(",", ".");
        const value = parseFloat(valueStr);
        return {
            value: isNaN(value) ? 0.0 : value,
            type: this.cbType.selectedItem ?? DiscountType.Percent,
        };
    }
    public set value(discount: IDiscount | undefined | null) {
        if (discount) {
            this.tbValue.text =
                discount.type === DiscountType.Fixed
                    ? this.formatMoney(discount.value)
                    : this.formatNumber(discount.value);
            this.cbType.selectedItem = discount.type;
        } else {
            this.tbValue.text = "0";
            this.cbType.selectedItem = DiscountType.Percent;
        }
    }

    private formatMoney(value: number): string {
        return Formatter.money(value, {
            locale: this.locale,
            grouping: false,
            showNullFraction: false,
        });
    }

    private formatNumber(value: number): string {
        return Formatter.number(value, {
            locale: this.locale,
            grouping: false,
        });
    }

    private _currency: Currency | null = null;
    public get currency(): Currency | null {
        return this._currency;
    }
    public set currency(currency: Currency | null) {
        this._currency = currency;

        const cbTypeOptions = this.currency ? { currency: this.currency } : undefined;
        this.cbType.textField = t => DiscountUtils.getTypeText(t, cbTypeOptions);
    }

    private _locale: Locale = Locale.RU;
    public get locale(): Locale {
        return this._locale;
    }
    public set locale(locale: Locale) {
        this._locale = locale;

        const cbTypeOptions = this.currency ? { currency: this.currency } : undefined;
        this.cbType.textField = t => DiscountUtils.getTypeText(t, cbTypeOptions);
    }

    public get disabled(): boolean {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this.tbValue.disabled = value;
        this.cbType.disabled = value;

        this._disabled = value;
    }

    private lbLabel: Label;
    private pnPanel: Panel;
    private tbValue: TextBox;
    private cbType: ComboBox<DiscountType>;

    public constructor() {
        super();

        this.lbLabel = new Label();
        this.lbLabel.id = "discount.label";
        this.lbLabel.text = this.label;
        this.lbLabel.class = "text-sm ml-0.5";

        this.tbValue = new TextBox();
        this.tbValue.id = "discount.value";
        this.tbValue.class = "w-66% mr-0.5";
        this.tbValue.text = "0";
        this.tbValue.validation = "required|money|unsigned";
        this.tbValue.addTextChangedHandler(() => this.notifyValueChangedHandlers());

        this.cbType = new ComboBox<DiscountType>();
        this.cbType.id = "discount.type";
        this.cbType.items = DiscountUtils.getTypes();
        this.cbType.selectedItem = DiscountType.Percent;
        this.cbType.class = "w-33%";
        const cbTypeOptions = this.currency ? { currency: this.currency } : undefined;
        this.cbType.textField = t => DiscountUtils.getTypeText(t, cbTypeOptions);
        this.cbType.addChangedHandler(() => this.notifyValueChangedHandlers());

        this.pnPanel = new Panel();
        this.pnPanel.id = "discount.controls";
        this.pnPanel.class = "flex";
        this.pnPanel.addControls([this.tbValue, this.cbType]);

        this.addControls([this.lbLabel, this.pnPanel]);
    }

    private _valueChangedHandlers = new Set<EventHandler<ValueChangedEventArgs<IDiscount>>>();
    public addValueChangedHandler(handler: EventHandler<ValueChangedEventArgs<IDiscount>>): void {
        this._valueChangedHandlers.add(handler);
    }
    public removeValueChangedHandler(handler: EventHandler<ValueChangedEventArgs<IDiscount>>): void {
        this._valueChangedHandlers.delete(handler);
    }
    private notifyValueChangedHandlers(): void {
        const args: ValueChangedEventArgs<IDiscount> = { value: this.value };
        for (const handler of this._valueChangedHandlers) {
            handler(this, args);
        }
    }

    public equals(discount: IDiscount): boolean {
        return discount.type === this.value.type && discount.value === this.value.value;
    }
}
