import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { BButton } from "bootstrap-vue";
import { ValidationObserver } from "vee-validate";
import {
    ISale,
    IOrderType,
    IOrderTypeField,
    IOrderTypeFieldGroup,
    OrderTypeFieldGroupType,
    ISaleInfoChangeDto,
    FieldType,
} from "@lib";
import ControlComponent from "@core/components/alt-ui/controls/control.component";
import { FieldControl } from "@core/types/field-controls/field-control";
import { FieldControlFactory } from "@core/types/field-controls/field-control-factory";
import { DiscountBoxFieldControl } from "@core/types/field-controls/custom/discount-box.field-control";
import { AppException } from "@/core/exceptions";

@Component({
    components: {
        BButton,
        ValidationObserver,
        ControlComponent,
    },
})
export default class SaleViewInfo extends Vue {
    @Prop({ required: true })
    private sale!: ISale;

    // @Prop({ required: true })
    // private saleType!: IOrderType;

    @Prop({ type: Boolean, default: false })
    private readonly!: boolean;

    private editMode = false;
    private fieldGroup: IOrderTypeFieldGroup | null = null;
    private controls: FieldControl[] = [];

    private get title(): string {
        return this.fieldGroup?.name ?? "Информация";
    }

    @Watch("sale", { immediate: true, deep: true })
    private onSaleChanged(): void {
        this.initValues();
    }

    @Watch("saleType", { immediate: true, deep: true })
    private onSaleTypeChanged(): void {
        this.initValues();
    }

    private initValues(): void {
        //if (!this.sale || !this.saleType) {
        if (!this.sale) {
            return;
        }

        this.editMode = false;
        //this.fieldGroup = this.saleType.groups.find(g => g.type === OrderTypeFieldGroupType.Info) ?? null;

        this.initControls();
    }

    private initControls(): void {
        this.controls = [];

        const context = {
            locale: this.sale.shopRef?.info?.locale,
        };

        for (const field of this.getFields()) {
            const fieldControl =
                field.id === "discount"
                    ? new DiscountBoxFieldControl(field, context)
                    : FieldControlFactory.fromField(field, context);

            fieldControl.value = this.getFieldValue(this.sale, field);
            this.controls.push(fieldControl);
        }
    }

    public getFields(): IOrderTypeField[] {
        // return (
        //     this.fieldGroup?.fields
        //         .filter(f => !f.hidden)
        //         .sort((a, b) =>
        //             a.sequence !== undefined && b.sequence !== undefined ? a.sequence - b.sequence : -1,
        //         ) ?? []
        // );

        return [
            {
                id: "discount",
                title: "Скидка",
                sequence: 10,
            },
            {
                id: "comment",
                type: FieldType.Text,
                title: "Комментарий",
                sequence: 20,
            },
        ];
    }

    public getFieldValue(sale: ISale, field: IOrderTypeField): any {
        if (field.custom) {
            // TODO: remove details1
            const id = field.customId ?? field.details1 ?? field.id;
            return sale.custom ? sale.custom[id] : "";
        }

        return this.getStandartFieldValue(sale, field);
    }

    private getStandartFieldValue(sale: ISale, field: IOrderTypeField): any {
        switch (field.id) {
            // case "name":
            //     return sale.info?.name;
            // case "description":
            //     return sale.info?.description;
            case "createDate":
                return sale.info?.createDate;
            // case "deadline":
            //     return sale.info?.deadline;
            case "discount":
                return sale.info?.discount;
            case "comment":
                return sale.info?.comment;
        }

        return "";
    }

    private getInfoDto(): ISaleInfoChangeDto {
        const dto: ISaleInfoChangeDto = {};

        for (const fieldControl of this.controls) {
            this.populateStandartFieldValue(dto, fieldControl.field, fieldControl.value);
        }

        return dto;
    }

    private getCustomDto(): any {
        const dto: any = {};

        for (const fieldControl of this.controls) {
            this.populateCustomFieldValue(dto, fieldControl.field, fieldControl.value);
        }

        return dto;
    }

    private populateCustomFieldValue(dto: any, field: IOrderTypeField, value: any): void {
        if (!field.custom) {
            return;
        }

        const id = field.customId ?? field.id;
        dto[id] = value;
    }

    private populateStandartFieldValue(dto: ISaleInfoChangeDto, field: IOrderTypeField, value: any): void {
        if (field.custom) {
            return;
        }

        switch (field.id) {
            // case "name":
            //     sale.info.name = value;
            //     return;
            // case "description":
            //     sale.info.description = value;
            //     return;
            case "createDate":
                dto.createDate = value;
                return;
            // case "deadline":
            //     dto.deadline = value;
            //     return;
            case "discount":
                dto.discount = value;
                return;
            case "comment":
                dto.comment = value;
                return;
        }
    }

    private startEditInfo(): void {
        this.initControls();
        this.editMode = true;
    }

    private async applyEditInfo(): Promise<void> {
        try {
            const result = await (this.$refs["saleInfoValidator"] as any).validate();
            if (!result) {
                return;
            }

            if (!this.sale) {
                throw new AppException("Продажа не задана.");
            }

            const infoDto = this.getInfoDto();
            const customDto = this.getCustomDto();

            const usecase = this.$alt.system.usecase.createSaleUseCase();
            const sale = await usecase.changeInfo(this.sale.company, this.sale.id, infoDto, customDto);

            this.editMode = false;
            this.$emit("sale-changed", sale);
        } catch {
            this.$alt.toast.error("Не удалось изменить данные продажи.");
        }
    }

    private cancelEditInfo(): void {
        this.initValues();
    }
}
