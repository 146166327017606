import { DiscountType, IAccount, IDiscount, ITemplateDocument, ISaleStage, IShop, Locale, SaleStageType } from "@lib";
import { Modal } from "@core/components/alt-ui/modal";
import {
    Button,
    CheckBoxSelect,
    CheckBoxSelectChangedEventArgs,
    Control,
    IconPackage,
    Panel,
    Select,
    TextBox,
    TextChangedEventArgs,
} from "@core/components/alt-ui/controls";
import { ValueChangedEventArgs } from "@core/components/alt-ui";
import { DiscountBox } from "@core/controls/discount-box";
import { StringUtils } from "@/utils/string.utils";
import { Formatter } from "@/utils/formatter";

export interface ISaleCompleteModalContext {
    paidSum: number;
    actualPrice: number;
    discount?: IDiscount;

    shop?: IShop;
    stages: ISaleStage[];
    accounts: IAccount[];
    documents: ITemplateDocument[];
    documentsSelected: ITemplateDocument[];
}

export interface ISaleCompleteModalData {
    amount: number;
    discount: IDiscount;
    account: IAccount;
    stage: ISaleStage;
    documents: ITemplateDocument[];
}

export enum SaleCompleteModalAnswer {
    Complete = "complete",
    Cancel = "cancel",
}

export interface ISaleCompleteModalResult {
    answer: SaleCompleteModalAnswer;
    data?: ISaleCompleteModalData;
}

export class SaleCompleteModal extends Modal<ISaleCompleteModalContext, ISaleCompleteModalResult> {
    private cbStage!: Select<ISaleStage>;
    private tbActualPrice!: TextBox;
    private dbDiscount!: DiscountBox;
    private tbPaidSum!: TextBox;
    private tbTotalToPay!: TextBox;
    private cbAccount!: Select<IAccount>;
    private cbsPrint!: CheckBoxSelect<ITemplateDocument>;

    private pnlFooter!: Panel;
    private btnCancel!: Button;
    private btnSave!: Button;

    private context: ISaleCompleteModalContext | null = null;

    private paymentsSum = 0.0;
    private actualPrice = 0.0;
    private discount: IDiscount = { value: 0.0, type: DiscountType.Percent };
    private total = 0.0;

    public constructor() {
        super("sale-complete-modal", "Завершение продажи");
        this.initializeControls();
    }

    public show(context: ISaleCompleteModalContext): Promise<ISaleCompleteModalResult> {
        this.context = context;
        this.initializeControls();
        this.populateControls(context);
        return super.show();
    }

    protected initializeControls(): void {
        this.cbStage = new Select<ISaleStage>();
        this.cbStage.id = "sale-complete.stage";
        this.cbStage.label = "Перевести продажу на этап";
        this.cbStage.iconPackage = IconPackage.Feater;
        this.cbStage.icon = "CheckCircleIcon";
        this.cbStage.items = this.context?.stages ?? [];
        this.cbStage.textField = s => s.name;
        this.cbStage.descriptionField = a => a.description;

        this.tbActualPrice = new TextBox();
        this.tbActualPrice.id = "sale-complete.actual-price";
        this.tbActualPrice.label = "Фактическая цена";
        //this.tbActualPrice.validation = "required|money|unsigned";
        this.tbActualPrice.disabled = true;

        this.dbDiscount = new DiscountBox();
        this.dbDiscount.id = "sale-complete.discount";
        this.dbDiscount.label = "Скидка";
        this.dbDiscount.addValueChangedHandler(this.changeDiscount.bind(this));

        this.tbPaidSum = new TextBox();
        this.tbPaidSum.id = "sale-complete.paid";
        this.tbPaidSum.label = "Оплачено";
        this.tbPaidSum.disabled = true;

        this.tbTotalToPay = new TextBox();
        this.tbTotalToPay.id = "sale-complete.total";
        this.tbTotalToPay.label = "Сумма к оплате";
        this.tbTotalToPay.validation = "required|money|unsigned";
        this.tbTotalToPay.addTextChangedHandler(this.changeTotalPay.bind(this));

        this.cbAccount = new Select<IAccount>();
        this.cbAccount.id = "sale-complete.account";
        this.cbAccount.label = "Счёт";
        this.cbAccount.iconPackage = IconPackage.Alt;
        this.cbAccount.icon = "finance";
        this.cbAccount.items = this.context?.accounts ?? [];
        this.cbAccount.textField = a => a.info.name;
        this.cbAccount.descriptionField = a => a.info.description;
        this.cbAccount.selectedIndex = this.cbAccount.items.length > 0 ? 0 : -1;

        this.cbsPrint = new CheckBoxSelect<ITemplateDocument>();
        this.cbsPrint.id = "sale-complete.print";
        this.cbsPrint.class = "w-full";
        this.cbsPrint.items = this.context?.documents ?? [];
        this.cbsPrint.textField = d => d.name;
        this.cbsPrint.addChangedHandler(this.changeDocuments.bind(this));
        this.cbsPrint.selectedItems = this.context?.documentsSelected ?? [];

        //

        this.btnCancel = new Button();
        this.btnCancel.id = "sale-complete.cancel";
        this.btnCancel.text = "Отменить";
        this.btnCancel.class = "mr-0.75";
        this.btnCancel.variant = "outline-danger";
        this.btnCancel.addClickHandler(this.clickCancel.bind(this));

        this.btnSave = new Button();
        this.btnSave.id = "sale-complete.save";
        this.btnSave.text = "Оплатить";
        this.btnSave.addClickHandler(this.clickSave.bind(this));

        this.pnlFooter = new Panel();
        this.pnlFooter.class = "flex justify-end mt-2";
        this.pnlFooter.addControls([this.btnCancel, this.btnSave]);
    }

    private populateControls(context: ISaleCompleteModalContext): void {
        const index = this.cbStage.items.findIndex(s => s.type === SaleStageType.Closed);
        this.cbStage.selectedIndex = index >= 0 ? index : 0;

        this.paymentsSum = context.paidSum;
        this.actualPrice = context.actualPrice;
        this.discount = context.discount ?? { value: 0.0, type: DiscountType.Percent };
        this.total = 0.0;
        this.calculateTotal();

        this.tbPaidSum.text = this.formatMoney(this.paymentsSum);
        this.tbActualPrice.text = this.formatMoney(this.actualPrice);
        this.dbDiscount.value = this.discount;
        this.tbTotalToPay.text = this.formatMoney(this.total);
    }

    private formatMoney(value: number): string {
        return Formatter.money(value, {
            locale: this.context?.shop?.info.locale ?? Locale.RU,
            grouping: false,
            showNullFraction: false,
        });
    }

    public get controls(): Control[] {
        return [
            this.cbStage,
            this.tbActualPrice,
            this.dbDiscount,
            this.tbPaidSum,
            this.tbTotalToPay,
            this.cbAccount,
            this.cbsPrint,
            this.pnlFooter,
        ];
    }

    private async changeDocuments(sender: any, e: CheckBoxSelectChangedEventArgs<ITemplateDocument>): Promise<void> {
        if (e.items.length === 0) {
            this.cbsPrint.label = "не печатать документы";
            return;
        }

        const form = StringUtils.decline(e.items.length, ["документ", "документа", "документов"]);
        this.cbsPrint.label = `напечать ${e.items.length} ${form}`;
    }

    private async changeDiscount(sender: any, e: ValueChangedEventArgs<IDiscount>): Promise<void> {
        this.discount = e.value;
        this.calculateTotal();
        this.tbTotalToPay.text = this.formatMoney(this.total);
    }

    private async changeTotalPay(sender: any, e: TextChangedEventArgs): Promise<void> {
        this.total = StringUtils.moneyStringToNumber(e.text);
        this.calculateActualPrice();
        this.tbActualPrice.text = this.formatMoney(this.actualPrice);
    }

    private calculateTotal(): void {
        const discountTotal = this.getDiscountTotal();
        this.total = this.actualPrice - discountTotal - this.paymentsSum;
    }

    private calculateActualPrice(): void {
        const discountTotal = this.getDiscountTotal();
        this.actualPrice = this.total + discountTotal + this.paymentsSum;
    }

    private getDiscountTotal(): number {
        const discount = this.discount.value;
        if (this.discount.type === DiscountType.Percent) {
            return (this.actualPrice * discount) / 100;
        } else if (this.discount.type === DiscountType.Fixed) {
            return discount;
        }
        return 0.0;
    }

    private async clickCancel(sender: any, e: any): Promise<void> {
        this.hide({ answer: SaleCompleteModalAnswer.Cancel });
    }

    private async clickSave(sender: any, e: any): Promise<void> {
        const valid = await this.validate();
        if (!valid) {
            return;
        }

        if (!this.context || !this.cbStage.selectedItem || !this.cbAccount.selectedItem) {
            return;
        }

        const data: ISaleCompleteModalData = {
            amount: parseFloat(this.total.toFixed(2)),
            discount: this.discount,
            account: this.cbAccount.selectedItem,
            stage: this.cbStage.selectedItem,
            documents: this.cbsPrint.selectedItems,
        };

        this.hide({
            answer: SaleCompleteModalAnswer.Complete,
            data: data,
        } as ISaleCompleteModalResult);
    }
}
