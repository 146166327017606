import { IWarranty, WarrantyType } from "@lib";
import { EventHandler, ValueChangedEventArgs } from "../components/alt-ui";
import { ComboBox, ComboBoxChangedEventArgs, Label, Panel, TextBox } from "../components/alt-ui/controls";
import { WarrantyUtils } from "../../utils/types/warranty.utils";

export class WarrantyBox extends Panel {
    public getComponentName(): string {
        return "PanelComponent";
    }

    private _label = "";
    public get label(): string {
        return this._label;
    }
    public set label(label: string) {
        this._label = label;
        this.lbLabel.text = label;
    }

    public get value(): IWarranty {
        const type = this.cbType.selectedItem ?? WarrantyType.Month;
        return {
            value: type === WarrantyType.Inf ? "" : this.tbValue.text,
            type: type,
        };
    }
    public set value(warranty: IWarranty | undefined) {
        if (warranty) {
            this.tbValue.text = warranty.value;
            this.cbType.selectedItem = warranty.type;
        } else {
            this.tbValue.text = "";
            this.cbType.selectedItem = WarrantyType.Month;
        }
    }

    public get disabled(): boolean {
        return this._disabled;
    }
    public set disabled(value: boolean) {
        this.tbValue.disabled = value;
        this.cbType.disabled = value;

        this._disabled = value;
    }

    private lbLabel: Label;
    private pnPanel: Panel;
    private tbValue: TextBox;
    private cbType: ComboBox<WarrantyType>;

    public constructor() {
        super();

        this.lbLabel = new Label();
        this.lbLabel.id = "warranty.label";
        this.lbLabel.text = this.label;
        this.lbLabel.class = "text-sm ml-0.5";

        this.tbValue = new TextBox();
        this.tbValue.id = "warranty.value";
        this.tbValue.class = "w-66% mr-0.5";
        this.tbValue.addTextChangedHandler(() => this.notifyValueChangedHandlers());

        this.cbType = new ComboBox<WarrantyType>();
        this.cbType.id = "warranty.type";
        this.cbType.items = WarrantyUtils.getTypes();
        this.cbType.textField = WarrantyUtils.getTypeText;
        this.cbType.selectedItem = WarrantyType.Month;
        this.cbType.class = "w-33%";
        this.cbType.addChangedHandler(this.onTypeChanged.bind(this));

        this.pnPanel = new Panel();
        this.pnPanel.id = "warranty.controls";
        this.pnPanel.class = "flex";
        this.pnPanel.addControls([this.tbValue, this.cbType]);

        this.addControls([this.lbLabel, this.pnPanel]);
    }

    private onTypeChanged(sender: any, e: ComboBoxChangedEventArgs<WarrantyType>): void {
        if (e.item === WarrantyType.Inf) {
            this.tbValue.disabled = true;
            this.tbValue.text = "";
        } else {
            this.tbValue.disabled = false;
        }

        this.notifyValueChangedHandlers();
    }

    private _valueChangedHandlers = new Set<EventHandler<ValueChangedEventArgs<IWarranty>>>();
    public addValueChangedHandler(handler: EventHandler<ValueChangedEventArgs<IWarranty>>): void {
        this._valueChangedHandlers.add(handler);
    }
    public removeValueChangedHandler(handler: EventHandler<ValueChangedEventArgs<IWarranty>>): void {
        this._valueChangedHandlers.delete(handler);
    }
    private notifyValueChangedHandlers(): void {
        const args: ValueChangedEventArgs<IWarranty> = { value: this.value };
        for (const handler of this._valueChangedHandlers) {
            handler(this, args);
        }
    }
}
